@import 'theme/main';
@import 'theme/variables.scss';

main,
main > section {
  display: flex;
  flex-direction: column;
}

main {
  height: 100%;
}

.toast {
  border-radius: $borderRadius;
}
