// fonts
//$font-default: Roboto, Lato, -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif;
$font-default: 'Trebuchet MS', 'Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans', Tahoma, sans-serif;
$serif-font: 'Times New Roman', 'Times', 'Times-Roman', 'serif';

// colors
$black: #000;
$white: #fff;
$creamWhite: #ffffcc;
$gray: #b1b1b1;
$mediumgray: #757575;
$lightgray: #dadada;
$verylightgray: #f0f0f0;
$darkgray: rgba(0, 0, 0, 0.16);
// $red: #e31515;
$red: #ff3300;
$dangerRed: #69140e;
$green: #009900;

// media
$mobile-width: 576px;
$tablet-width: 768px;
$desktop-width: 1366px;

@mixin mobile {
  @media (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width - 1px}) and (max-width: #{$desktop-width - 1}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

// RoundUp field colors (field border, button fill)
$fieldColor: #bd6421;
$basicOrange: #ca6d23;
$basicColor: rgba(120, 64, 28, 1);
$basicDarkerColor: rgba(98, 51, 28, 1);
$basicDarkestColor: rgba(52, 28, 15, 1);
$basicLightTransparent: rgba(163, 107, 53, 0.5);
$borderRadius: 4px;
$navbar: #ce8147;
$rowHighlight: #ce8147;
$backgroundAndFontWhite: #f1e8d6;

// dimensions multiple components depend on
$sidebarWidthDesktop: 360px;
$sidebarWidthTablet: 240px;

// different colors for mobile view
$mobileTextColor: black;
$mobileContentBackground: rgba($backgroundAndFontWhite, 0.3);
