@import 'theme/variables.scss';

html {
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
  height: 100%;
}

body {
  height: 100%;
  min-height: 100%;
  -webkit-overflow-scrolling: touch;
  margin: 0;
  padding: 0;
  font-family: $font-default;
  font-size: 16px;
  color: $basicDarkestColor;

  @include tablet {
    font-size: 14px;
  }

  @include mobile {
    font-size: 14px;
  }
}

:global(#root) {
  height: 100%;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}
